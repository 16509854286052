<template>
  <!-- inner-hero-section start -->
  <div class="inner-hero-section style--five"></div>
  <!-- inner-hero-section end -->

  <template v-if="$store.getters.account != null">
    <!-- user section start -->
    <div class="mt-minus-150 pb-120">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="user-card">
              <div class="avatar-upload">
                <div class="obj-el">
                  <img
                    src="@/assets/images/elements/team-obj.png"
                    alt="image"
                  />
                </div>
                <div class="avatar-preview">
                  <div
                    id="imagePreview"
                    :style="`background-image: url(${getAvatar(
                      $store.getters.account
                    )})`"
                  ></div>
                </div>
              </div>
              <h3 class="user-card__name">
                {{
                  $store.getters.account.profile.firstname != null
                    ? `${$store.getters.account.profile.firstname}`
                    : `@${$store.getters.account.ucode}`
                }}
              </h3>
              <span class="user-card__id">
                {{ $store.getters.account.username }}
              </span>
            </div>
            <!-- user-card end -->
            <div class="user-action-card">
              <ul class="user-action-list">
                <li class="active">
                  <router-link :to="{ name: 'personal-information' }" tag="a">
                    {{ $t("text.personal-information") }}
                  </router-link>
                </li>
                <li><a href="user-transaction.html">Transactions</a></li>
                <li><a href="user-referral.html">Referral Program</a></li>
                <li><a href="user-lottery.html">Favorite Lotteries</a></li>
                <li><a href="contact.html">Help Center</a></li>
                <li><a href="#0">Log Out</a></li>
              </ul>
            </div>
            <!-- user-action-card end -->
          </div>
          <div class="col-lg-8 mt-lg-0 mt-5">
            <div class="user-info-card">
              <div class="user-info-card__header">
                <h3 class="user-info-card__title">
                  {{ $t("text.personal-details") }}
                </h3>
              </div>
              <ul class="user-info-card__list">
                <li>
                  <span class="caption">
                    {{ $t("text.name") }}
                  </span>
                  <span class="value">
                    {{
                      $store.getters.account.profile.firstname != null
                        ? `${$store.getters.account.profile.firstname} ${$store.getters.account.profile.lastname}`
                        : ""
                    }}
                  </span>
                </li>
                <li>
                  <span class="caption">
                    {{ $t("text.date-of-birth") }}
                  </span>
                  <span class="value">
                    {{
                      moment(new Date($store.getters.account.profile.birthdate)).format(
                        "MM/DD/YYYY"
                      )
                    }}
                  </span>
                </li>
                <li>
                  <span class="caption">
                    {{ $t("text.addresses") }}
                  </span>
                  <span class="value">
                    {{ $store.getters.account.profile.addresses }}
                  </span>
                </li>
              </ul>
            </div>
            <!-- user-info-card end -->
            <div class="user-info-card">
              <div class="user-info-card__header">
                <h3 class="user-info-card__title">
                  {{ $t("text.account-settings") }}
                </h3>
              </div>
              <ul class="user-info-card__list">
                <li>
                  <span class="caption">
                    {{ $t("text.language") }}
                  </span>
                  <span class="value">English (United States)</span>
                </li>
                <li>
                  <span class="caption">
                    {{ $t("text.status") }}
                  </span>
                  <span class="value status-active">Active</span>
                </li>
              </ul>
            </div>
            <!-- user-info-card end -->
            <div class="user-info-card">
              <div class="user-info-card__header">
                <h3 class="user-info-card__title">
                  {{ $t("text.email-address") }}
                </h3>
              </div>
              <ul class="user-info-card__list">
                <li>
                  <span class="caption">
                    {{ $t("text.email") }}
                  </span>
                  <span class="value">
                    {{ $store.getters.account.username }}
                  </span>
                </li>
              </ul>
            </div>
            <!-- user-info-card end -->
            <div class="user-info-card">
              <div class="user-info-card__header">
                <h3 class="user-info-card__title">
                  {{ $t("text.phone") }}
                </h3>
              </div>
              <ul class="user-info-card__list">
                <li>
                  <span class="caption">
                    {{ $t("text.mobile") }}
                  </span>
                  <span class="value">
                    {{ $store.getters.account.profile.phone }}
                  </span>
                </li>
              </ul>
            </div>
            <!-- user-info-card end -->
          </div>
        </div>
      </div>
    </div>
    <!-- user section end -->
  </template>
</template>

<script>
import moment from "moment";

export default {
  name: "PersonalInformation",
  setup() {
    return {
      moment: moment,
    };
  },
};
</script>